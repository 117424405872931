

    .option-list {

        .option {
            margin-bottom: calc(var(--global-margin) * 0.4);
            padding-top:    calc(var(--global-margin) * 0.4);
            padding-right:  calc(var(--global-margin) * 0.7);
            padding-bottom: calc(var(--global-margin) * 0.4);
            padding-left:   calc(var(--global-margin) * 0.7 - 4px);

            cursor: pointer;

            border-left: solid 4px transparent;
            box-shadow:  0 0 6px 1px rgba(0,0,0,0.2);
            transition: box-shadow 200ms, border 200ms;

            &:hover {
                box-shadow:  0 0 12px 3px rgba(0,0,0,0.3);
            }

            [dir=rtl] & {
                border-left: none;
                border-right: solid 4px transparent;
            }
        }

        .radio-dummy {
            position: relative;
            border: 2px solid var(--global-muted-color);
            height: calc(var(--global-margin) * 1.1);
            width: calc(var(--global-margin) * 1.1);
            border-radius: 50%;
            margin-right:  calc(var(--global-margin));
            transition: border 200ms, background 200ms;

            [dir=rtl] & {
                margin-right:  0;
                margin-left:  calc(var(--global-margin));
            }

            &:after {
                content: ' ';
                position: absolute;
                height: calc(var(--global-margin) * 0.50);
                width: calc(var(--global-margin) * 0.50);
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background: var(--global-inverse-color);
                border-radius: 50%;
                opacity: 0;
                transition: opacity 200ms;

            }

        }

        .selected {
            border-color: var(--global-primary-background);

            .radio-dummy {
                background: var(--global-primary-background);
                border-color: var(--global-primary-background);

                &:after {
                    opacity: 1;

                }
            }
        }
    }


